<template>
  <div>
    <!-- 顶部title开始 -->
    <div class="widthCommit">
      <p class="getVehicleTitle">报价列表页</p>
    </div>
    <!-- 顶部title结束 -->
    <!-- 车型报价modelQuotation开始 -->
    <div class="modelQuotation widthCommit">
      <!-- 搜索区域开始 -->
      <el-form :inline="true" :model="getDate" class="demo-form-inline">
        <el-form-item label="车辆型号">
          <el-input
            class="inp"
            v-model="getDate.model"
            placeholder="请输入准确车辆型号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="botton" @click="changeGet(0)" plain
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 搜索区域结束 -->
      <!-- form表单开始 -->
      <el-form :inline="true" :model="getDate" class="demo-form-inline">
        <el-form-item class="functionDiv" label="车辆品牌">
          <span style="position: absolute; left: -104px; color: red">*</span>
          <el-select v-model="getDate.brand" placeholder="请选择">
            <div class="sctionDiv">
              <div id="sctionDivTit" class="sctionDivTit">
                <p
                  v-for="item in optionsOne"
                  @click="oooo(item.initials)"
                  :key="item.initials"
                >
                  {{ item.initials }}
                </p>
              </div>
              <div class="sctionTow">
                <el-option-group
                  v-for="(group, index) in optionsOne"
                  :key="index"
                  :label="group.initials"
                  :id="group.initials"
                >
                  <el-option
                    style="text-align: center; font-size: 8px; color: #666666"
                    v-for="item in group.brands"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </div>
            </div>
          </el-select>
        </el-form-item>

        <el-form-item class="functionDiv functionDivOne" label="报价时间">
          <!-- <el-input v-model="formInline.user" placeholder="请输入准确车辆型号"></el-input> -->
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            @change="formListTime"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <!-- form表单结束 -->
      <!-- form表单开始 -->
      <el-form :model="formInline" class="demo-form-inline">
        <el-form-item class="functionDiv" label="功能类型">
          <template>
            <div
              class="functionType"
              :class="{ active: isActive === type }"
              @click="addCategory(type)"
              v-for="(item, type) in list"
              :key="type"
            >
              {{ item.type }}
            </div>
          </template>
        </el-form-item>
        <el-form-item
          v-show="showActiveType == true"
          class="functionDiv"
          label="车辆类型"
        >
          <template>
            <div
              class="functionType"
              @click="addType(type)"
              :class="{ active: isActiveType === type }"
              v-for="(item, type) in b"
              :key="type"
            >
              {{ item.type }}
            </div>
          </template>
        </el-form-item>
        <div v-show="!showActiveType" style="width: 100%; height: 50px"></div>
      </el-form>
      <!-- form表单结束 -->
      <!-- 按钮开始 -->
      <div class="functionBtn">
        <div>
          <el-button class="funBotton" type="info" @click="resetButton"
            >重置</el-button
          >
          <el-button
            class="funBotton funBottonOne"
            type="primary"
            @click="changeGet(1)"
            >查询</el-button
          >
        </div>
      </div>
      <!-- 按钮结束 -->
    </div>
    <!-- 车型报价modelQuotation结束 -->

    <!-- 车辆信息型号infoModel开始 -->
    <div v-show="infoModelShow" class="infoModel widthCommit">
      <div class="infoModelTitle">
        <el-popover
          v-for="(item, index) in listCar"
          :key="index"
          placement="bottom"
          width="900"
          trigger="click"
          ref="aa"
        >
          <div class="infoModelMianList">
            <div
              v-for="(ite, ind) in item.list"
              :key="ind"
              style="cursor: pointer"
              class="infoModelMianListOne"
              @click="aaa(ite, item.name, index)"
            >
              {{ ite }}
            </div>
          </div>
          <div
            :class="[mouseenterActive == index ? 'mouseenterActiveClass' : '']"
            @mouseenter="mouseenter(index)"
            @mouseout="mouseout(index)"
            slot="reference"
            class="infoModelTextTitle"
          >
            {{ item.title }}
          </div>
        </el-popover>

        <div style="right: 20px; position: absolute">
          <el-button
            @click="infoReset"
            class="funBotton funBottonOne funBottonOneOne"
            size="mini"
            plain
            type="primary"
            >重置</el-button
          >
        </div>
      </div>
      <div
        v-show="divActive"
        style="
          box-sizing: border-box;
          padding: 16px 20px 0px 0px;
          display: flex;
        "
      >
        <el-tag
          style="margin-left: 20px"
          effect="plain"
          v-show="item != ''"
          v-for="(item, index) in listBottom"
          closable
          :key="index"
          @close="handleClose(index)"
          >{{ item }}</el-tag
        >
      </div>
    </div>

    <!-- 车辆配置信息configurationInfo开始 -->
    <div class="configurationInfo widthCommit">
      <el-table
        :header-cell-style="{
          background: '#BFDFF7',
          color: '#333333',
          fontSize: '18px',
          fontWeight: 'normal',
        }"
        :data="tableData"
        style="width: 100%"
      >
        <!-- 图片 -->
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <div class="imagee">
              <img
                class="img"
                :src="scope.row.imageUrl"
                @click="imageDeati(scope.row)"
                alt
              />
              <div class="classText">
                <!-- <span class="spanText" v-if="scope.row.imageCount==''">{{scope.row.imageCount}}</span> -->
                <div class="spanText">
                  <span class="spanText" v-if="!scope.row.imageCount == ''"
                    >{{ scope.row.imageCount }}张</span
                  >
                </div>
                <!-- <span class="spanText">上传图片</span> -->
              </div>
              <!-- <img class="img"  :src="scope.row.imageUrl" @click="imageDeati(scope.row)" alt=""> -->
              <!-- {{scope.row.imageUrl}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车辆配置信息" width="410">
          <template slot-scope="scope">
            <span class="tableTitle">
              {{ scope.row.brand }}
              {{ scope.row.type }}
              {{ scope.row.name }}
              <!-- {{ scope.row.horsepower }}马力
              {{ (scope.row.outerLength / 1000).toFixed(1) }}米 -->
              <span v-if="scope.row.horsepower"
                >{{ scope.row.horsepower }}马力&nbsp;
              </span>
              <span v-if="scope.row.outerLength"
                >{{ (scope.row.outerLength / 1000).toFixed(1) }}米&nbsp;
              </span>
              {{ scope.row.fuelType }}
              {{ scope.row.emissionStandard }}
            </span>
            <div class="tableNewMian">
              <div class="tableNewMianOne">
                <div>车辆型号：{{ scope.row.model }}</div>
                <div>
                  发动机型号：{{ scope.row.engineBrand }}
                  {{ scope.row.engineModel }}
                </div>
              </div>
              <div class="tableNewMianOne" style="width: 70px">
                <div>驱动：{{ scope.row.driveType }}</div>
                <div>车长：{{ scope.row.outerLength }}</div>
              </div>
              <div class="tableNewMianOne" style="width: 70px">
                <div>速比：{{ scope.row.speedRatio }}</div>
                <div>厢长：{{ scope.row.cargoBoxLength }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          label="报价地区"
          prop="emissionStandard"
        ></el-table-column>
        <el-table-column align="right" width="100">
          <template>
            <div style="height: 45px">厂商成交价:</div>
            <div style="height: 20px; margin-top: 4px">近期成交价:</div>
          </template>
        </el-table-column>

        <el-table-column align="left" label="参考价">
          <template slot-scope="scope">
            <div v-show="scope.row.guidePrice" class="referencePrice">
              <span class="referencePriceMian"
                >{{ scope.row.guidePrice }}万元</span
              >
              <div class="referencePriceMianTitle">厂商指导价:</div>
            </div>
            <div v-show="!scope.row.guidePrice" class="referencePrice">
              <span class="referencePriceMian">暂无</span>
              <div class="referencePriceMianTitle">厂商指导价:</div>
            </div>
            <div v-show="scope.row.recentPrice" class="referencePrice">
              <div class="referencePriceMian">
                {{ scope.row.recentPrice }}万元
              </div>
              <div class="referencePriceMianTitle referencePriceMianTitleOne">
                近期成交价:
              </div>
            </div>
            <div v-show="!scope.row.recentPrice" class="referencePrice">
              <div class="referencePriceMian">暂无</div>
              <div class="referencePriceMianTitle referencePriceMianTitleOne">
                近期成交价:
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="最近报价" width="100">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="offer(scope.row)">报价</el-button> -->
            <p v-show="scope.row.guidePriceOffer" class="offerText">
              <span style="font-weight: bold">
                {{ scope.row.guidePriceOffer }}
              </span>
              <span style="font-size: 12px">万元</span>
            </p>
            <p
              v-show="scope.row.recentPriceOffer && !scope.row.guidePriceOffer"
              class="offerText"
              style="line-height: 38px"
            >
              暂无
            </p>
            <!-- <p v-show="!scope.row.guidePriceOffer" class="offerText">万元</p> -->
            <p v-show="scope.row.recentPriceOffer" class="offerText">
              <span style="font-weight: bold">
                {{ scope.row.recentPriceOffer }}
              </span>
              <span style="font-size: 12px">万元</span>
            </p>
            <p
              v-show="!scope.row.recentPriceOffer && scope.row.guidePriceOffer"
              class="offerText"
              style="line-height: 38px"
            >
              暂无
            </p>
          </template>
        </el-table-column>
        <el-table-column label="报价信息" width="170">
          <template slot-scope="scope">
            <div style="text-align: left">
              <span
                style="
                  font-size: 12px;
                  color: #333333;
                  font-family: PingFang SC;
                  font-weight: 500;
                "
                >账号:{{ scope.row.mobilePhone }}</span
              >
            </div>
            <div style="text-align: left">
              <span
                style="
                  font-size: 12px;
                  color: #333333;
                  font-family: PingFang SC;
                  font-weight: 500;
                "
                >时间:{{ scope.row.createdTime }}</span
              >
            </div>
            <!-- <el-button type="text">报价</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageNumber"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 车辆配置信息configurationInfo结束 -->
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      indexListOne: null, //判断是 上面的搜索还是下面的搜索
      divActive: false,
      listCar: [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ],

      mouseenterActive: null, //控制高亮
      given: true,
      optionsOne: [], //上面的数据
      showActiveType: false,
      getDate: {
        model: "", //车辆型号
        brand: "", //品牌
        category: "", //功能类型
        type: "", //车型类型
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      a: [],
      list: [],
      b: [],
      formInline: {
        user: "",
        region: "",
      },
      value: "",
      isActive: null,
      isActiveType: null,
      value2: [],
      tableData: [], //下面table的数据
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 显示隐藏
      infoModelShow: false, // 车辆信息型号infoModel 显示隐藏
      engineModel: false, //发动机型号的显示隐藏
      enginePower: false, //发动机马力
      isActiveDriveTypes: null, //驱动形式下排第一个
      isActiveEngineBrand: null, //发动机品牌 下排第二个
      isActiveEngineModel: null, //发动机型号 下排第三个
      isActiveEnginePower: null, //发动机马力 下排第四个
      isActiveOverallLength: null, //车外廓长 下排第五个
      isActivespeedRatio: null, //速比 下排第六个
      isActiveContainerLength: null, //货箱长 下排第七个
      isActiveEmissionStandards: null, //国五国六
      listBottom: {
        driveType: "", //驱动形式,
        engineBrand: "", //发动机品牌,
        engineModel: "", //发动机型号,
        horsepower: "", //马力
        outerLength: "", //车外廓长
        cargoBoxLength: "", //货箱长
        speedRatio: "", //速比1
        emissionStandard: "", //国五 国六
      },

      // 最后显示的
      showDriveTypes: true, //驱动形式
      showEngineBrandList: true, //发动机
      showOverallLengthList: true, //车外廓长
      showSpeedRatioList: true, //速比
      showContainerLengthList: true, //货箱长
      showEmissionStandards: true, //国五国六
      pages: {
        pageSize: 10,
        pageNumber: 1,
      },
      total: null,
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    // 进入页面
    async RequestData() {
      // return;
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleBrands`,
        data: {},
      });
      console.log(res.content);
      if (res.resultStates == 1) return this.$message.error("暂无报价");
      this.optionsOne = res.content;

      // console.log("111")
    },
    // 功能类型/车辆类型
    async RequestList() {
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getVehicleCategory`,
        data: {},
      });
      console.log(res.content);
      this.a = res.content;
      this.list = [];
      this.a.forEach((item) => {
        this.list.push({ type: item.category });
      });
    },
    // 重置按钮
    resetButton() {
      this.getDate.model = "";
      this.getDate.brand = "";
      this.getDate.category = "";
      this.getDate.type = "";
      this.getDate.startTime = ""; //开始时间
      this.getDate.endTime = ""; //结束时间
      this.isActive = null;
      this.isActiveType = null;
      this.infoModelShow = false;
      this.showActiveType = false;
      this.given = true;
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.value2 = [];
    },
    // 点击左侧字母
    oooo(item) {
      console.log(item);
      let element = document.getElementById(item);
      // element.scrollIntoView({ block: "center", inline: "nearest" });
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    // 点击下标
    addCategory(index) {
      this.isActive = index;
      this.b = [];
      if (this.a[index].types.length == 0) {
        // 如果没有车辆类型，则车辆类型消失
        this.showActiveType = false;
      } else {
        this.showActiveType = true;
      }
      this.a[index].types.forEach((item) => {
        this.b.push({
          type: item,
        });
      });
      this.getDate.type = "";
      this.getDate.category = this.a[index].category;
      this.isActiveType = null;
      this.given = false;
    },
    //选择下标  车辆类型
    addType(index) {
      this.isActiveType = index;
      console.log(this.b[index].type);
      this.getDate.type = this.b[index].type;
      this.given = false;
    },

    formListTime(e) {
      // this.getDate.startTime = Date.parse(new Date(e[0]));
      // this.getDate.endTime = Date.parse(new Date(e[1]));
      if (e) {
        this.getDate.startTime = e[0];
        this.getDate.endTime = e[1];
      } else {
        this.getDate.startTime = "";
        this.getDate.endTime = "";
      }

      // this.getAddList();
      //       startTime: "", //开始时间
      // endTime: "", //结束时间

      // console.log(new Date().getTime())
    },
    // 点击下面的重置
    infoReset() {
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.given = true;
      this.changeGet(this.indexListOne);
    },
    // 点击提交
    async changeGet(index) {
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      this.pages = {
        pageSize: 10,
        pageNumber: 1,
      };
      this.indexListOne = index; //确认点击的是哪一个按钮
      if (index == 0) {
        this.getDate.brand = "";
        this.getDate.category = "";
        this.getDate.type = "";
        this.getDate.startTime = "";
        this.getDate.endTime = "";
        if (this.getDate.model == "") return this.$message.error("输入为空");
        var a = {
          model: this.getDate.model,
        };
      } else {
        if (this.getDate.brand == "") return this.$message.error("输入为空");
        this.getDate.model = "";
        var a = {
          model: this.getDate.model,
          brand: this.getDate.brand,
          category: this.getDate.category,
          type: this.getDate.type,
          startTime: this.getDate.startTime,
          endTime: this.getDate.endTime,
        };
      }
      this.listBottom = {
        driveType: "", //驱动形式,
        engineBrand: "", //发动机品牌,
        engineModel: "", //发动机型号,
        horsepower: "", //马力
        outerLength: "", //车外廓长
        cargoBoxLength: "", //货箱长
        speedRatio: "", //速比1
        emissionStandard: "", //国五 国六
      };
      // this.engineModel = false; //发动机型号的显示隐藏
      // this.enginePower = false; //发动机马力

      console.log(this.getDate);
      this.given = true;
      const { data: res } = await this.$http({
        method: "post",
        url: `api/vehicleOffer/getOfferFilter`,
        data: a,
      });
      if (res.content == null) {
        this.tableData = [];
        // this.listCar = null;
        this.infoModelShow = false;
        return this.$message.error("暂无报价");
      }
      console.log(this.listCar);
      console.log(res.content.emissionStandards);
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        console.log(array[i]);
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      this.listCar = array;
      this.infoModelShow = true; //下面进行展示
      this.quotationForm();
    },
    // 点击下面的按钮，点一下 发一下请求
    async quotationForm() {
      if (this.given) {
        let a = {
          model: this.getDate.model,
          brand: this.getDate.brand,
          category: this.getDate.category,
          type: this.getDate.type,
          startTime: this.getDate.startTime,
          endTime: this.getDate.endTime,
          ...this.pages,
        };
        const { data: res } = await this.$http({
          method: "post",
          url: `/api/vehicleOffer/getVehicleOffers`,
          data: a,
        });
        if (res.resultStates == 1) return this.$message.error("暂无报价");
        if (res.content == null) {
          this.tableData = [];
          this.$message.error("暂无报价");
          this.infoModelShow = false;

          return;
        }
        console.log(res.content);
        this.total = res.content.count;
        this.tableData = res.content.items;
      } else {
        this.tableData = [];
        let a = Object.assign(this.getDate, this.listBottom, this.pages);
        console.log(a);
        const { data: res } = await this.$http({
          method: "post",
          url: `/api/vehicleOffer/getVehicleOffers`,
          data: a,
        });
        if (res.resultStates == 1) return this.$message.error("暂无报价");
        if (res.content == null) {
          this.tableData = [];
          this.$message.error("暂无报价");
          this.infoModelShow = false;
          return;
        }
        console.log(res.content);
        this.total = res.content.count;
        this.tableData = res.content.items;
      }
    },
    handleCurrentChange(e) {
      console.log(e);
      this.pages.pageNumber = e;
      this.quotationForm();
    },
    handleSizeChange(e) {
      this.pages.pageSize = e;
      this.quotationForm();
    },
    // 两个鼠标事件
    mouseenter(id) {
      this.mouseenterActive = id;
    },
    mouseout(id) {
      this.mouseenterActive = null;
    },
    async aaa(ite, data, index) {
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];

      this.listBottom[`${data}`] = ite;
      this.divActive = true;
      this.$refs.aa[index].doClose();
      this.mouseenterActive = null;
      this.given = false;
      this.pages.pageNumber = 1;
      this.quotationForm();
      let a = {
        model: this.getDate.model,
        brand: this.getDate.brand,
        category: this.getDate.category,
        type: this.getDate.type,
      };
      console.log(a);
      let obj = { ...a, ...this.listBottom };
      // 找自身的调整
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getOfferFilter`,
        data: obj,
      });
      console.log(res);
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      this.listCar = array;
    },
    async handleClose(tap) {
      this.listBottom[`${tap}`] = "";
      var qqq = 0;
      for (var b in this.listBottom) {
        if (this.listBottom[b] == "") {
          qqq = qqq + 1;
        }
      }
      if (qqq == 8) {
        this.divActive = false;
      }
      this.given = false;
      this.pages.pageNumber = 1;
      this.quotationForm();
      let a = {
        model: this.getDate.model,
        brand: this.getDate.brand,
        category: this.getDate.category,
        type: this.getDate.type,
      };
      this.listCar = [
        { title: "排放标准", list: [], name: "emissionStandard" },
        { title: "发动机品牌", list: [], name: "engineBrand" },
        { title: "发动机型号", list: [], name: "engineModel" },
        { title: "马力(Ps)", list: [], name: "horsepower" },
        { title: "货箱长(mm)", list: [], name: "cargoBoxLength" },
        { title: "车外廓长(mm)", list: [], name: "outerLength" },
        { title: "驱动形式", list: [], name: "driveType" },
        { title: "速比", list: [], name: "speedRatio" },
      ];
      let obj = { ...a, ...this.listBottom };
      // 找自身的调整
      const { data: res } = await this.$http({
        method: "post",
        url: `/api/vehicleOffer/getOfferFilter`,
        data: obj,
      });
      if (res.content.emissionStandards.length != 0) {
        this.listCar[0].list = res.content.emissionStandards;
      } else {
        this.listCar[0] = null;
      }
      if (res.content.engineBrands.length != 0) {
        this.listCar[1].list = res.content.engineBrands;
      } else {
        this.listCar[1] = null;
      }
      if (res.content.engineModels.length != 0) {
        this.listCar[2].list = res.content.engineModels;
      } else {
        this.listCar[2] = null;
      }
      if (res.content.horsepower.length != 0) {
        this.listCar[3].list = res.content.horsepower;
      } else {
        this.listCar[3] = null;
      }
      if (res.content.cargoBoxLengths.length != 0) {
        this.listCar[4].list = res.content.cargoBoxLengths; // 货箱长
      } else {
        this.listCar[4] = null;
      }
      if (res.content.outerLengths.length != 0) {
        this.listCar[5].list = res.content.outerLengths; // 车外廓长
      } else {
        this.listCar[5] = null;
      }
      if (res.content.driveTypes.length != 0) {
        this.listCar[6].list = res.content.driveTypes; // 驱动行驶
      } else {
        this.listCar[6] = null;
      }
      if (res.content.speedRatios.length != 0) {
        this.listCar[7].list = res.content.speedRatios; // 速比
      } else {
        this.listCar[7] = null;
      }
      var array = this.listCar;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      this.listCar = array;
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 在实例初始化之后，组件属性计算之前，如data属性等
   */
  beforeCreate() {},
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * 在挂载开始之前被调用：相关的 render 函数首次被调用。
   */
  beforeMount() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    this.RequestData();
    this.RequestList();
    this.quotationForm();
  },
  /**
   * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
   * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
   */
  beforeUpdate() {},
  /**
   * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
   * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
   */
  updated() {},
  /**
   * keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
   */
  activated() {},
  /**
   * keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
   */
  deactivated() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  // beforeRouteLeave(to, from, next) {
  //   if (to.path == "/addVehicleOffer") {
  //     to.meta.keepAlive = true;
  //   }
  //   console.log(from);
  //   console.log(to);
  //   next();
  // },
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<style scoped lang='less'>
.widthCommit {
  width: 1000px;
  margin: 0 auto;
}
.getVehicleTitle {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
// 车型开始
.modelQuotation {
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 10px #bfdff7;
  opacity: 1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 24px 30px 0 64px;
}

/deep/ .el-form-item__label {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  opacity: 1;
  margin-right: 20px;
}
/deep/ .botton {
  margin-left: 40px;
}
/deep/.inp {
  width: 106%;
}
.functionType {
  cursor: pointer;
  float: left;
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
/deep/.functionDiv {
  margin-bottom: 10px;
}
/deep/.functionDivOne {
  //   margin-bottom: 10px;
  margin-left: 22px;
}
.functionBtn {
  width: 100%;
  height: 36px;
}
.functionBtn > div {
  float: right;
}
/deep/.funBotton {
  width: 116px;
  margin-right: 30px;
}
/deep/.funBottonOne {
  width: 116px;
  margin-right: 0;
}
// 车型结束
.infoModel {
  background: #ffffff;
  box-shadow: 0px 0px 10px #bfdff7;
  opacity: 1;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 24px;
}
// 车辆配置信息configurationInfo开始
.configurationInfo {
  margin-top: 40px;
}
.tableTitle {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: #333333;
  opacity: 1;
}
.tableDiv {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
}
.tableLi {
  //   width: 70px;
  height: 20px;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 4px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.referencePrice {
  position: relative;
  height: 50px;
  box-sizing: border-box;
  padding-top: 14px;
}
.referencePriceMian {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
.referencePriceMianTitle {
  position: absolute;
  right: 142px;
  top: 14px;
  width: 65px;
  height: 20px;
  // background: #c7e0f9;
  border-radius: 16px 16px 16px 0px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.referencePriceMianTitleOne {
  right: 142px;
}
.titleP {
  margin: 0;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: #333333;
}
.p1 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #db1818;
}
// /deep/.el-form--inline .el-form-item__label {
//   width: 100px;
// }
/deep/.functionDiv {
  margin-bottom: 10px;
}
.sctionDiv {
  display: flex;
}
.sctionDivTit {
  width: 30px;
  position: absolute;
  z-index: 9999;
  background: #f4f7fe;
  height: 100%;
}
.sctionDivTit p {
  text-align: center;
  font-size: 7px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  height: 9px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sctionTow {
  flex: 1;
  position: relative;
}
/deep/.el-select-group__title {
  padding: 0 !important;
  background: #f4f7fe;
  font-size: 8px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1883e3;
  opacity: 1;
}
// 车辆配置信息configurationInfo结束
// 动态样式
.active {
  color: #1883e3 !important;
}
.infoModelTitle {
  display: flex;
  align-items: center;
  background: #ecf3fd;
  height: 66px;
  position: relative;
}
.infoModelTextTitle {
  width: 104px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  opacity: 1;
  height: 66px;
  font-size: 16px;
  text-align: center;
  // line-height: 66px;
  padding-top: 21px;
  box-sizing: border-box;
  cursor: pointer;
}
.infoModelMianList {
  display: flex;
  flex-wrap: wrap;
}
.infoModelMianListOne {
  width: 14%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.mouseenterActiveClass {
  font-size: 16px;
  font-family: PingFang SC;
  background: #bfdff7;
  color: #333333;
  font-weight: 600;
}
/deep/.funBottonOneOne {
  width: 70px;
  margin-left: 15px;
}
.tableNewMian {
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
.tableNewMianOne {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  opacity: 1;
}
// /deep/ .el-table th > .cell {
//   text-align: left !important;
// }
.offerText {
  // font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #db1818;
  height: 29px;
}
.imagee {
  cursor: pointer;
  width: 80px;
  height: 60px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png");
  background-size: 100% 100%;
  /* background: #000; */
}
.img {
  width: 80px;
  height: 60px;
  position: relative;
}
.classText {
  width: 50px;
  height: 60px;
  position: absolute;
  top: 76px;
  left: 26px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 14px;
  color: #ffffff;
  opacity: 1;
}
.spanText {
  width: 50px;
  height: 15px;
  display: block;
  text-align: center;
}
</style>
